// Test env

import { ApiError } from "./ApiError";
import { RequestMetadata } from "./RequestMetadata";
import { AddressResponse } from "../models/Address/AddressResponse";
import { CustomerResponse, PremisesType } from "../models/Customer";
import AssetDetails from "../models/Customer/AssetDetails";
import Tenancies from "../models/Customer/Tenancies";
import PremisesEligibility from "../models/Customer/PremisesEligibility";
import CustomerProfile from "../models/Customer/CustomerProfile";
import ReferenceData from "../models/ReferenceData/ReferenceDataResponse";
import {
  EligibilityOutcome,
  EligibilityResponse,
  PremisesDetails,
} from "../models/Property";
import { RepairReason } from "../models/Repair/RepairReason";
import { WorksOrderRequest } from "../models/WorksOrders/WorksOrderRequest";
import { CurrentRepair, RepairHistory } from "../models/Repair/Repair";
import { WorksOrder, WorksOrdersResponse } from "../models/WorksOrders";
import { ContractorV2 } from "../models/Repair/ContractorV2";
import { ContractorPriority } from "../models/Repair/Contractor";
import moment from "moment";
import { SorDeterminationResponse} from "../models/WorksOrders";
import { RepairsResponse, RepairStatus } from "../models/Repairs";
import { NamespaceValue } from "../models/Repair/NamespaceValue";
import { uuid } from "uuidv4";
import CSSTokenModel from "../models/Customer/CSSTokenModel";
import { createBrowserHistory } from "history";
import { isUndefinedNullOrEmpty } from "../utils/translation/helper";
import { trackEvent } from "../appInsights/TelemetryService";
import { CancelEligibilityOutcome } from "../models/Property/Eligibility";
import axios from "axios";
import { caseResponseData } from "./../models/Case/Case";
import CaseSearchData from "../models/Case/CaseSearch";
import {EnhancedRepairPayload, EnhancedRepairResponse} from "../models/EnhancedRepair/index";


//import { v4 as uuidv4 } from 'uuid';
const history = createBrowserHistory();
const apimBaseUrl = process.env.REACT_APP_ADDRESSAPI_BASE_URL;
const ergoApiUrl = process.env.REACT_APP_ERGOAPI_BASE_URL;
const transctionApiBaseUrl = process.env.REACT_APP_TRANSACTIONAPI_BASE_URL;
const apimKey = process.env.REACT_APP_APIM_KEY;
const origin = process.env.REACT_APP_WEB_APP_HOSTNAME;
const channel_identifier = process.env.REACT_APP_X_CHANNEL_IDENTIFIER;
const agent_identifier = process.env.REACT_APP_X_AGENT_IDENTIFIER;
const forwarded_for = process.env.REACT_APP_X_FORWARDED_FOR;
const content_type = process.env.REACT_APP_CONTENT_TYPE;
const if_match = process.env.REACT_APP_IF_MATCH;
const cache_control = process.env.REACT_APP_CACHE_CONTROL;
const tokenApiBaseUrl = process.env.REACT_APP_LOGIN_TOKEN_API_BASE_URL;
const accessTokenApiBaseUrl = process.env.REACT_APP_ACCESS_TOKEN_API_BASE_URL;
const resetPasswordTokenApiBaseUrl =
  process.env.REACT_APP_RESET_TOKEN_API_BASE_URL;
const registrationTokenApiBaseUrl =
  process.env.REACT_APP_REGISTRATION_TOKEN_API_BASE_URL;

// API version 1
const addressApiUrl = `${apimBaseUrl}/customerselfserve/api/v1`;
const assetapiApiUrl = `${apimBaseUrl}/customerselfserve/api/v2`;
const referenceApiUrl = `${apimBaseUrl}/customerselfserve/api/v1`;

type ApiResponse<T> = Promise<T>;
const ApiResponse = Promise;

const customerApiUrl = `${apimBaseUrl}/customerselfserve/api/v1`;
// const customerApiUrl = `${apimBaseUrl}/customerselfserve/api/v2`;
const tenanciesApiUrl = `${apimBaseUrl}/customerselfserve/api/v1`;
const premisesEligibilityApiUrl = `${apimBaseUrl}/customerselfserve/api/v2`;
//const worksordersApiUrl = `${apimBaseUrl}/works-orders/api/v1`
const worksordersApiUrl = `${apimBaseUrl}/customerselfserve/api/v2`;
const referenceDataV2ApiUrl = `${apimBaseUrl}/customerselfserve/api/v2`;
const contractorV2ApiUrl = `${apimBaseUrl}/customerselfserve/api/v2`;
const premisesApiUrl = `${apimBaseUrl}/customerselfserve/api/v2`;
const tokenApiUrl = `${tokenApiBaseUrl}?`;
const accessTokenApiUrl = `${accessTokenApiBaseUrl}?`;
const resetPasswordTokenApiUrl = `${resetPasswordTokenApiBaseUrl}?`;
const registrationTokenApiUrl = `${registrationTokenApiBaseUrl}?`;
const allPayGatewayApiUrl = `${apimBaseUrl}/customerselfserve/api/v1/allpay`;

const accountsApiUrl = `${apimBaseUrl}/customerselfserve/api/v1`;
const caseAssetApiUrl = `${apimBaseUrl}/customerselfserve/api/v2`

//const transactionApiUrl = `${apimBaseUrl}/customerselfserve/api/v1`
const transactionApiUrl = `${apimBaseUrl}/customerselfserve/api/v1`;
const historyItemsApiUrl = `${apimBaseUrl}/customerselfserve/api/v2`;
const contractorApiUrl = `${apimBaseUrl}/customerselfserve/api/v2`;
const appconfigApiUrl = `${apimBaseUrl}/customerselfserve/api/v1`;
const droolAPIUrl = `${apimBaseUrl}/customerselfserve`;
const mediaApiUrl = `${apimBaseUrl}/customerselfserve/api/`;
const eligilibilityDeterminationApiURL = `${apimBaseUrl}/customerselfserve`;
//to do when deployed on selfserve
const cancelRepairApiUrl = `${apimBaseUrl}/customerselfserve`;
const clearSessionUrl = `${apimBaseUrl}/customerselfserve/api/v1`;
const enhancedRepairEligibilityApiURL = `${apimBaseUrl}/customerselfserve`;
export const defaultError = (err: { status: number }) => ({
  type: "https://tools.ietf.org/html/rfc7231",
  title: "Error",
  status: err.status,
});

export const isApiError = (obj: any): obj is ApiError => {
  if (!obj) return false;
  if (obj?.statusCode?.toString().startsWith("4")) return true;
  return obj.type && obj.status;
};

let getAccessToken: () => Promise<string> = () => Promise.resolve("");
export const setAccessTokenHandler = (getToken: () => Promise<string>) => {
  getAccessToken = getToken;
};

const request =
  (method: string, responseType: string = '') =>
    (basePath: string) =>
      async (path?: string, data?: object, metadata?: RequestMetadata) => {
        // We check to see if we are running Cypress, if so,
        // we use the generated idtoken set in the localStorage
        // const accessToken = localStorage.getItem('accessToken') || ""
        const _channel = localStorage.getItem("channel") || "";
        const _customerId = localStorage.getItem("customerId") || "";
        const _corelationId = localStorage.getItem("corelationId") || "";
        const _sessionId = localStorage.getItem("sessionId") || "";

        trackEvent(
          window.location.pathname,
          process.env.REACT_APP_X_CHANNEL_IDENTIFIER,
          _customerId,
          _corelationId,
          _sessionId
        );
        const opt: any = {
          method,
          headers: {
            Authorization: "",
            "Ocp-Apim-Subscription-Key": apimKey,
            Origin: origin,
            "Cache-Control": cache_control,
            "if-Match": metadata?.eTag || if_match, // N.B. empty string currently avoids validation of eTag - should probably prevent call in the first place if it's not present
            "x-channel-identifier": !isUndefinedNullOrEmpty(_channel)
              ? _channel
              : channel_identifier,
            "X-Correlation-Identifier": _corelationId,
            "x-session-identifier": _sessionId,
            "x-agent-identifier": !isUndefinedNullOrEmpty(_customerId)
              ? _customerId
              : agent_identifier,
            "x-forwarded-for": forwarded_for,
            "Content-Type": content_type,
            ...(data && {
              "Content-Type": content_type,
            }),
          },
          responseType: responseType,

          ...(data && { body: JSON.stringify(data) }),
        };

        if (responseType === 'blob') {
          return axios.get(`${basePath}${path}`, opt)
            .then((response) => {
              return response;
            }).catch((error) => {
              return error
            })
        } else {
          return fetch(`${basePath}${path}`, opt).then(
            async (res) => {
              const response = await res.json().catch(() => null);
              if (res.status === 401) {
                window.localStorage.clear();
                window.location.assign(window.location.origin);
                history.push("/startpage");
                window.location.reload();
                //throw new ApiError({ status: res.status })
              }

              if (!res.ok) {
                throw new ApiError(
                  { status: res.status, title: response.title },
                  response.errors
                );
              }
              if (!data) {
                // If it's not post, we inject the eTag that used to come as a part of the body, in the response.
                const eTagInHeader = res.headers.get("eTag");
                if (eTagInHeader) {
                  return { ...response, eTag: eTagInHeader };
                }
              }

              const locationHeader = res.headers.get("Location");
              if (locationHeader) {
                return { ...response, location: locationHeader };
              }
              return response;
            },
            (err) => {
              throw new ApiError({ status: err.status });
            }
          );
        }
      };

export const get = request("GET");
export const put = request("PUT");
export const patch = request("PATCH");
export const post = request("POST");
export const deleteRequest = request("DELETE");
export const getAsBlob = request("GET", 'blob');

// GET REQUEST
export const getAddresses = async (
  _postcode: string
): ApiResponse<AddressResponse> => {
  const addressResponse = await get(addressApiUrl)(
    `addresses?postcode=${_postcode}`
  );
  if (addressResponse.length > 0) {
  }
  return addressResponse;
};

export const getCustomer = (id: string): ApiResponse<CustomerResponse> =>
  get(customerApiUrl)(`/customers/${id}`);

export const getAssociatedAssets = (
  assetId: string
): ApiResponse<AssetDetails> =>
  get(assetapiApiUrl)(`/assets/${assetId}/assets?expand=address`);

export const getAssetsByid = (assetId: string): ApiResponse<AssetDetails> =>
  get(assetapiApiUrl)(`/assets/${assetId}`);

export const getTenancies = (customerId: string): ApiResponse<Tenancies[]> =>
  get(tenanciesApiUrl)(`/customers/${customerId}/tenancies`);

export const getAccountByCustomers = (id: string): ApiResponse<any> =>
  get(accountsApiUrl)(`/customers/${id}/accounts`);

export const getReferenceData = (
  namespacenames: string
): ApiResponse<ReferenceData[]> =>
  get(referenceApiUrl)(
    `/reference-data/assets/reference-data?namespacenames=${namespacenames}`
  );

export const GetAppConfigToggleValue = async (
  key: string
): ApiResponse<any> => {
  const appConfigResponse = await get(appconfigApiUrl)(`/configuration/${key}`);
  
  return appConfigResponse;
};

export const postPremisesEligibility = (
  premiseId: string,
  tenancyGroup: string
): ApiResponse<PremisesEligibility[]> =>
  post(premisesEligibilityApiUrl)(
    `/assets/${premiseId}/eligibilityoutcomes`,
    { tenancyGroup }
  );

export const getTenanciesGroup = (
  customerId: string,
  premiseId: string
): ApiResponse<Tenancies[]> =>
  get(tenanciesApiUrl)(
    `/customers/${customerId}/premises/${premiseId}/tenancies`
  );

export const getAccount = (id: string): ApiResponse<any> =>
  get(accountsApiUrl)(`/accounts/${id}`);

export const getTransactionsSearch = (
  id: string,
  Limit: number,
  Skip: number,
  DateFrom?: string,
  DateTo?: string,
  searchText?: string,
  transactiontype?: string
): ApiResponse<any> =>
  get(transactionApiUrl)(
    `/accounts/${id}/transactions?pageNumber=1&pageSize=3&Limit=${Limit}&Skip=${Skip}&DateFrom=${DateFrom}&DateTo=${DateTo}&searchText=${searchText}&${transactiontype}`
  );

export const getTransactions = (id: string): ApiResponse<any> =>
  get(transactionApiUrl)(
    `/accounts/${id}/transactions?pageNumber=1&pageSize=3`
  );

export const getRepairEligibility = (
  premisesType: string,
  maintenanceResponsibility: string,
  repairResponsibility: string,
  additionalRepairInfo: boolean,
  isRepairEligible: boolean | null,
  isCustomerView: boolean,
  sorCode: string | null,
  uprn: string,
  isInDefects: boolean,
  heatingRepairContext: string | null
): ApiResponse<EligibilityResponse> =>
  post(worksordersApiUrl)(`/works-orders/eligibilityoutcomes`, {
    premisesType,
    maintenanceResponsibility,
    repairResponsibility,
    additionalRepairInfo,
    isRepairEligible,
    isCustomerView,
    uprn,
    isInDefects,
    repairType: sorCode,
    heatingRepairContext,
  });

//reference data api call

const searchRepairReasonsV2 = (
  searchText: string,
  assetType: string,
  isCatalyst: boolean
): ApiResponse<RepairReason[]> =>
  get(referenceDataV2ApiUrl)(
    `/repair-reasons?searchText=${searchText}&assetType=${assetType}&isUsedForCreation=true&isCatalyst=${isCatalyst}`
  );
export const searchRepairReasons = (searchText: string, assetType: string, isCatalyst: boolean) =>
  searchRepairReasonsV2(searchText, assetType, isCatalyst);

export const createWorkOrderRepair = (data: Partial<CurrentRepair>) => {
  const worksOrder = new WorksOrderRequest(data);
  return post(worksordersApiUrl)("/works-orders", worksOrder);
};

export const updateWorkOrderRepair = (
  repair: Partial<CurrentRepair>,
  id: string,
  eTag: string
) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return patch(worksordersApiUrl)(
    `/works-orders/${id}`,
    new WorksOrderRequest(repair),
    { eTag }
  );
};
export const repaireWorkOrderUpdate = (repair: object, id: string) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return patch(worksordersApiUrl)(`/works-orders/${id}`, repair);
};

export const getDeterminedContractor = (
  identifier: string,
  priority: string,
  region: string,
  repairType: string,
  repairTime: string,
  uprn: string,
  raisedDate: string,
  repairCauseId: number,
  maintenanceResponsibility: string,
  repairResponsibility: string,
  borough: string
): ApiResponse<ContractorV2[]> => {
  return get(contractorV2ApiUrl)(
    `/worksorder/${identifier}/contractors?priority=${priority}&borough=${borough}&region=${region}&repairType=${repairType}&repairTime=${repairTime}&uprn=${uprn}&raisedDate=${raisedDate}&repairCauseId=${repairCauseId}&maintenanceResponsiblity=${maintenanceResponsibility}&repairResponsibility=${repairResponsibility}`
  );
};

export const getContractorPriority = ({
  contractorIdentifier,
  priority,
  isRecall,
  sorCode,
  isEMA,
}: {
  contractorIdentifier: string;
  priority: string;
  isRecall: boolean;
  sorCode: string;
  isEMA: boolean;
}): ApiResponse<ContractorPriority> => {
  const dateTime = moment().format("YYYY-MM-DD HH:mm");
  return get(contractorV2ApiUrl)(
    `/contractors/${contractorIdentifier}/workinghours?priority=${priority}&dateTime=${dateTime}&isRecall=${isRecall}&repairReason=${sorCode}&isEMA=${isEMA}`
  );
};

const getDuplicateRepairURL = (
  status: string,
  sor: string,
  location: string,
  priority: string,
  propertiesIds: string
): ApiResponse<WorksOrdersResponse> =>
  get(worksordersApiUrl)(
    `/search/works-orders?searchText=&orderBy=&raisedSince=&status=${status}&sor=${sor}&location=${location}&priority=${priority}&propertiesIds=${propertiesIds}`
  );
export const getDuplicateRepair = (
  status: string,
  sor: string,
  location: string,
  priority: string,
  propertiesIds: string
) => {
  getDuplicateRepairURL(status, sor, location, priority, propertiesIds);
};

type WorksOrderSearchParameters = {
  searchText?: string;
  orderBy?: string;
  limit?: number;
  skip?: number;
  raisedSince?: string;
  sor?: string;
  location?: string;
  propertiesIds: string[];
  priority?: string;
  status?: string;
  description?: string;
  isDuplicateCheck?: boolean;
};
export const getRepairStatusOptions = async () => {
  const repairStatusReferenceData = await get(referenceApiUrl)(
    `/repairs/reference-data?namespacenames=repair-statuses`
  );
  const repairStatusResponse: RepairStatus[] =
    repairStatusReferenceData[0].namespaces.map((namespace: NamespaceValue) => {
      const subCategories = [];
      if (namespace.namespace.name !== "Reported to EMA") {
        for (const keyValue of Object.entries(namespace.namespace.keyValues)) {
          const [, value] = keyValue;
          if (value !== "In Diagnosis") {
            subCategories.push(value);
          }
        }
        return {
          name: namespace.namespace.name,
          subCategoryNames: subCategories.length > 1 ? subCategories : null,
        };
      }
    });
  return repairStatusResponse;
};

export const getWorksOrdersApi = ({
  propertiesIds,
  limit = 10,
  skip,
  searchText = "",
  orderBy = "",
  raisedSince = "",
  sor = "",
  location = "",
  priority = "",
  status = "",
  description = "",
  isDuplicateCheck = false,
}: WorksOrderSearchParameters): ApiResponse<WorksOrdersResponse> => {
  const propertiesQueryParams = propertiesIds.join("&propertiesIds=");
  const limitQueryParam = !!limit ? `&limit=${limit}` : "";
  const skipQueryParam = !!skip ? `&skip=${skip}` : "";
  return get(worksordersApiUrl)(
    `/search/works-orders?searchText=${searchText}&orderBy=${orderBy}&raisedSince=${raisedSince}&status=${status}&sor=${sor}&location=${location}&priority=${priority}&propertiesIds=${propertiesQueryParams}${limitQueryParam}${skipQueryParam}&description=${description}&isDuplicateCheck=${isDuplicateCheck}`
   );
};

export const getWorkOrdersForResumeRepair = ({
  propertiesIds,
  searchText = "",
  orderBy = "",
  raisedSince = "",
  sor = "",
  location = "",
  priority = "",
  status = "",
  description = "",
}: WorksOrderSearchParameters): ApiResponse<WorksOrdersResponse> => {
  const propertiesQueryParams = propertiesIds.join("&propertiesIds=");
  return get(worksordersApiUrl)(
    `/search/works-orders?searchText=${searchText}&orderBy=${orderBy}&raisedSince=${raisedSince}&status=${status}&sor=${sor}&location=${location}&priority=${priority}&propertiesIds=${propertiesQueryParams}&description=${description}`
  );
};

export const getRepairs = async (
  params: RepairsSearchParameters
): ApiResponse<RepairsResponse> => {
  // TODO: remove all of these methods uppon refactoring for worksOrders search
  const {
    searchTerm,
    priority,
    resultsPerPage,
    orderBy,
    raisedSince,
    status,
    skip,
    propertiesIds,
    description,
  } = params;

  const queryParams = {
    orderBy,
    priority,
    status,
    skip,
    propertiesIds,
    raisedSince,
    limit: resultsPerPage,
    searchText: searchTerm,
    description: description,
  };

  const worksOrders = await getWorksOrdersApi(queryParams);
  const repairs = new RepairsResponse(worksOrders);
  return repairs;
};
type RepairsSearchParameters = {
  searchTerm: string;
  priority?: string;
  resultsPerPage: number;
  orderBy: string;
  status: string;
  skip: number;
  raisedSince: string;
  propertiesIds: string[];
  description?: string;
};
type SearchDuplicateRepairsParameters = {
  propertyId: string;
  sor: string;
  repairLocation: string;
};

export const searchDuplicateRepairs = async (
  params: SearchDuplicateRepairsParameters
): ApiResponse<RepairsResponse> => {
  const { sor, propertyId, repairLocation } = params;
  const propertiesIds = [];
  if (propertyId) propertiesIds.push(propertyId);

  const queryParams = {
    propertiesIds,
    sor,
    location: repairLocation,
    priority: encodeURIComponent("Next Available,Next Available (Recall)"),
  };
  const worksOrders = await getWorksOrdersApi(queryParams);
  const repairs = new RepairsResponse(worksOrders);
  return repairs;
};

export const getSorDetermination = ({
  propertyAttribute,
  dividedBlockAttribute,
  blockAttribute,
  estateAttribute,
  context,
}: {
  propertyAttribute: string;
  dividedBlockAttribute: string;
  blockAttribute: string;
  estateAttribute: string;
  context: PremisesType;
}): ApiResponse<SorDeterminationResponse> =>
  // Use default GUID for works-orders ID as we do not have an actual ID at this point
  post(worksordersApiUrl)(
    `/works-orders/00000000-0000-0000-0000-000000000000/sor-determination`,
    {
      context,
      propertyAttribute,
      dividedBlockAttribute,
      blockAttribute,
      estateAttribute,
    }
  );


  export const postEnhancedRepairEligibility =  (
    enhancedRepairPayload: EnhancedRepairPayload
  ): ApiResponse<EnhancedRepairResponse> =>
  post(enhancedRepairEligibilityApiURL)(`/api/v2/works-orders/enhanced-repair-eligibility`, enhancedRepairPayload);


//get workorder
export const currentRepairFromWorksOrder = async (
  worksOrder: WorksOrder
): Promise<CurrentRepair> => {
  let premisesInfo: PremisesDetails;

  if (worksOrder.identifiers?.asset?.assetIdentifier) {
    premisesInfo = await get(premisesApiUrl)(
      `/assets/${worksOrder.identifiers.asset.assetIdentifier}`
    );
    const currentRepair = new CurrentRepair(worksOrder, premisesInfo);
    return currentRepair;
  }
  return new CurrentRepair(worksOrder);
};

// export const getRepair = async (id: string): ApiResponse<CurrentRepair> => {
//   const worksOrderResponse: WorksOrder = await get(worksordersApiUrl)(`/works-orders/${id}`)
//   return currentRepairFromWorksOrder(worksOrderResponse)
// }

export const getRepair = async (id: string): ApiResponse<CurrentRepair> => {
  const worksOrderResponse: WorksOrder = await get(worksordersApiUrl)(
    `/works-orders/${id}`
  );

  return currentRepairFromWorksOrder(worksOrderResponse);
};

export const getWorkOrder = async (id: string): ApiResponse<CurrentRepair> => {
  const worksOrderResponse: WorksOrder = await get(worksordersApiUrl)(
    `/works-orders/${id}`
  );

  const currentRepair = new CurrentRepair(worksOrderResponse);
  return currentRepair;
};

export const GetCustomerId = (
  code: string,
  requestState: string
): ApiResponse<string> =>
  post(tokenApiUrl)(`code=${code}&state=${requestState}`, {});

export const GetAccessToken = (
  grant_type: string,
  refresh_token: string
): ApiResponse<string> =>
  post(accessTokenApiUrl)(
    `grant_type=${grant_type}&refresh_token=${refresh_token}`,
    {}
  );

export const GetResetPasswordToken = (code: string): ApiResponse<string> =>
  post(resetPasswordTokenApiUrl)(`code=${code}`, {});

// URL is similar to GetResetPasswordToken for getting access token
export const GetForgetPasswordToken = (code: string): ApiResponse<string> =>
  post(resetPasswordTokenApiUrl)(`code=${code}`, {});

// URL is similar to GetRegistrationToken for getting access token
export const GetRegistrationToken = (code: string): ApiResponse<string> =>
  post(registrationTokenApiUrl)(`code=${code}`, {});

export const getRepairHistories = async (
  workOrderID: string
): ApiResponse<RepairHistory> => {
  const repairHistoriesResponse = await get(historyItemsApiUrl)(
    `/works-orders/${workOrderID}/historyItems`
  );
  return repairHistoriesResponse;
};

export const deleteWorkOrder = async (
  workOrderID: string,
  eTag: any
): ApiResponse<RepairHistory> => {
  let metadata = { eTag: eTag };
  const deleteWorkOrderResponse = await deleteRequest(historyItemsApiUrl)(
    `/works-orders/${workOrderID}`,
    {},
    metadata
  );
  return deleteWorkOrderResponse;
};

export const putEditProfile = (
  customerId: string,
  profileData: object,
  eTag: string
): ApiResponse<CustomerProfile[]> =>
  put(customerApiUrl)(`/customers/${customerId}`, profileData, { eTag });

export const allPayTaken = async (
  paymentreference: string
): ApiResponse<any> => {
  const allPayGatewayResponse: WorksOrder = await get(allPayGatewayApiUrl)(
    `/user/${paymentreference}/token`
  );

  return allPayGatewayResponse;
};

export const getContractors = (): ApiResponse<any> =>
  get(contractorApiUrl)(`/contractors`);

export const getCancelRepairEligibility = (
  channel: string,
  status: string,
  isDraft: boolean,
  isEmergency: boolean,
  isOutOfHours: boolean,
  isRecall: boolean,
  isEMA: boolean,
  isPotentialDuplicate: boolean,
  contractorSiteIdentifier: string,
  location: string,
  trade: string,
  sorCode: string | null,
  raisedDate: string,
  assetTypeName: string,
  lastAppointmentDate: string,
  userIdentifier: string,
  raisedByIdentifier: string
): ApiResponse<CancelEligibilityOutcome> =>
  post(cancelRepairApiUrl)(`/wo-cancellation-eligibility`, {
    channel,
    status,
    isDraft,
    isEmergency,
    isOutOfHours,
    isRecall,
    isEMA,
    isPotentialDuplicate,
    contractorSiteIdentifier,
    location,
    trade,
    sorCode,
    raisedDate,
    assetTypeName,
    lastAppointmentDate,
    userIdentifier,
    raisedByIdentifier,
  });
export const getRescheduleEligibility = (
  channel: string,
  id: string,
  status: string,
  isDraft: boolean,
  isEmergency: boolean,
  isOutOfHours: boolean,
  isRecall: boolean,
  isEMA: boolean,
  isPotentialDuplicate: boolean,
  contractorSiteIdentifier: string,
  location: string,
  trade: string,
  sorCode: string | null,
  raisedDate: string,
  assetTypeName: string,
  lastAppointmentDate: string,
  userIdentifier: string,
  raisedByIdentifier: string,
  isContractorIntegrated: boolean,
  lastAppointmentCreatedDate : string
): ApiResponse<CancelEligibilityOutcome> =>
  post(cancelRepairApiUrl)(
    `/works-orders/${id}/appointments/reschedule-eligibility`,
    {
      channel,
      status,
      isDraft,
      isEmergency,
      isOutOfHours,
      isRecall,
      isEMA,
      isPotentialDuplicate,
      contractorSiteIdentifier,
      location,
      trade,
      sorCode,
      raisedDate,
      assetTypeName,
      lastAppointmentDate,
      userIdentifier,
      raisedByIdentifier,
      isContractorIntegrated,
    }
  );

export const clearSession = (): ApiResponse<any> =>
  deleteRequest(clearSessionUrl)(`/logout`);

export const getQuestionnaireMasterData = (): ApiResponse<any[]> =>
  get(referenceApiUrl)(
    `/reference-data/cases/reference-data?NamespaceNames=case-types`
  );

export const getDocumentsByCustomer = (customerID: string, data: any): ApiResponse<any> =>
  get(accountsApiUrl)(`/customers/${customerID}/documents?yearMonthFrom=${data.yearMonthFrom}&yearMonthTo=${data.yearMonthTo}`);

export const getDocumentByIdentifier = (customerID: string, documentID: string): ApiResponse<any> =>
  getAsBlob(accountsApiUrl)(`/customers/${customerID}/documents/${documentID}`);

export const postRequestCustomStatement = (
  customerID: string,
  requestData: object,
  eTag: string
): ApiResponse<any> =>
  post(customerApiUrl)(`/work-orders/${customerID}/communications`, requestData, { eTag });

  export const getCommunicationMessages = (customerID: string, requestData: any): ApiResponse<any> =>
  get(accountsApiUrl)(`/customers/${customerID}/communications?fromDate=${requestData.fromDate}&toDate=${requestData.toDate}&searchText=${requestData.searchText}&limit=${requestData.limit}&skip=${requestData.skip}&orderBy=${requestData.sort}&${requestData.assetIdentifier}&status=${requestData.status}`);

export const patchCommunicationMessages = (customerID: string, communicationID: string, status: {}): ApiResponse<any> =>
  patch(accountsApiUrl)(`/customers/${customerID}/communications/${communicationID}`, status);


export const createCaseData = (data: any): ApiResponse<caseResponseData> =>
  post(referenceApiUrl)(`/cases`, data);

  export const updateWorkOrderRepairMedia = (
    repair: Partial<CurrentRepair>,
    id: string,
    eTag: string
  ) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return patch(mediaApiUrl)(
      `v2/works-orders/${id}`,
      new WorksOrderRequest(repair),
      { eTag }
    );
  };
  
  export const getMediaData = (id: string): ApiResponse<any> =>
    get(customerApiUrl)(`/works-orders/${id}/documents`);
  
  export const getViewMediaDetail = (
    id: string,
    mediaId: string
  ): ApiResponse<any> =>
    get(customerApiUrl)(`/works-orders/${id}/documents/${mediaId}`);

  export const postMediaFile = (
      workOrderId: string,
      mediaData: object
  ): ApiResponse<any> =>
    post(mediaApiUrl)(`v1/works-orders/${workOrderId}/documents`, mediaData);

 export const deleteMediaFiles = (
      workOrderId: string,
      mediaId: string
  ): ApiResponse<any> =>
      deleteRequest(mediaApiUrl)(
        `v1/works-orders/${workOrderId}/documents/${mediaId}`
  );  

export const getCaseAssociatedAssets = (
  assetId: string
): ApiResponse<AssetDetails> =>
  get(caseAssetApiUrl)(`/assets/${assetId}/assets`);
export const getAssetByCaseid = (assetId: string): ApiResponse<AssetDetails> =>
  get(caseAssetApiUrl)(`/assets/${assetId}`);
export const getCaseAssetAssociate = (
  assetId: string
): ApiResponse<any> =>
  get(caseAssetApiUrl)(`/assets/${assetId}/associates`)

export const caseDroolAPI = (data: any): ApiResponse<any> =>
  post(droolAPIUrl)(`/case-team-determination`, data);

type CaseSearchParameters = {
  searchText?: string;
  orderBy?: string;
  limit?: number;
  skip?: number;
  priority?: string;
  status?: string;
  defaultassocitaedAsset?: string;
  customerId?: string
};

export const getCasesList = ({
  searchText = "",
  orderBy = "",
  limit = 10,
  skip,
  status = "",
  defaultassocitaedAsset = "",
  customerId = ""
}: CaseSearchParameters): ApiResponse<CaseSearchData> => {
  return get(referenceApiUrl)(
    `/cases?SearchText=${searchText}&OrderBy=${orderBy}&Status=${status}&Limit=${limit}&Skip=${skip}&${defaultassocitaedAsset}&customerId=${customerId}`
  );
};

export const repairPriorityDetermination = (description : string)  => {
  return post(eligilibilityDeterminationApiURL)(`/api/v1/repair/priority-determination`, {description})
}

export const postRequestCatalystCustomerEmail = (
  customerID: string,
  requestData: object
): ApiResponse<any> =>
  post(customerApiUrl)(
    `/work-orders/${customerID}/communications`,
    requestData
  );

  export const GetHighRiseProperty = (
    assetId: string
  ): ApiResponse<any> =>
    get(worksordersApiUrl)(`/assets/${assetId}/associates?expand=interestedParties`);

    export const getDocumentByBlockId = (
      assetId:string,
      type:string
    ): ApiResponse<any> => get(customerApiUrl)(`/assets/${assetId}/documents?DocumentType=${type}&Limit=1&Skip=0`)  
   
    export const getBuildingSafetyDoc = (
      assetid:string,
      docId:string
      ): ApiResponse<any> => get(customerApiUrl)(`/assets/${assetid}/documents/${docId}`)
    export const getFinancialReferralReasonData = (  ): ApiResponse<ReferenceData[]> =>
    get(referenceApiUrl)(
      `/reference-data/collection/reference-data?NamespaceNames=financial-referral-reason`
    );
  
  export const submitFinancialInclusion = (customerId:any, payref:any, data:any):ApiResponse<any> => 
    post(referenceApiUrl)(`/customer/${customerId}/account/${payref}/servicerequest`, data)
    
  

  